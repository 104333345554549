
export default {
  props: {
    fixture: {
      type: Object,
      required: true,
    },
  },
  computed: {
    score () {
      return this.fixture.score;
    },
    relativeResult () {
      return this.fixture.result;
    },
    competitionStyle () {
      return {
        background: this.fixture.competitionColours?.background,
        color: this.fixture.competitionColours?.text,
      };
    },
    opposition () {
      const fixtureTeam = this.fixture.fixtureTeams.find(
        team => team.side.name !== this.fixture.side.name,
      );
      return fixtureTeam?.team?.teamNames.length
        ? fixtureTeam.team.teamNames[0].displayName
        : false;
    },
    sideLabel () {
      return this.fixture.neutral ? `Neutral` : this.fixture.side.name;
    },
    sideAbbreviation () {
      return this.sideLabel.charAt(0);
    },
    status () {
      return `test`;
      // return this.fixture.displayType;
    },
    showScore () {
      return this.fixture.displayType === `Result` || this.fixture.displayType === `Live`;
    },
    showKickoff () {
      return this.fixture.displayType === `Fixture`;
    },
  },
};
