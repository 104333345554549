
export default {
  props: {
    fixture: {
      type: Object,
      required: true,
    },
    editorial: {
      type: Boolean,
      default: false,
    },
    redTv: {
      type: Boolean,
      default: false,
    },
    tickets: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Boolean,
      default: false,
    },
    stats: {
      type: Boolean,
      default: false,
    },
    addToCalendar: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      buttons: [],
      fixLink: {},
    };
  },
  async mounted () {
    this.fixLink = await this.getFixtureLinks(this.fixture.id);
    const fixLinkUrl = this.fixLink
      ? this.fixLink.slug.slice(0, -1)
      : `/match-centre?ref=${this.fixture.id}`;

    const hasEditorial = !fixLinkUrl.includes(`/match-centre`);

    /*
     * Editorial in this case links to the match centre, this can either be
     * via a preview/live/report (if found) or a straight link using the fixture ID
     */
    if (this.editorial) {
      const editorialTitle = this.fixLink
        ? this.deslugify(this.fixLink.type).replace(`Match `, ``)
        : `Info`;

      this.buttons.push({
        label: editorialTitle,
        url: fixLinkUrl,
        icon: `icon-chevron-right`,
        external: false,
        class: `fill-right`,
      });
    }

    /*
     * Media links to the media tab in the match centre, this can either be
     * via a preview/live/report (if found) or a straight link using the fixture ID
     */
    if (this.media && hasEditorial) {
      this.buttons.push({
        label: `Media`,
        url: `${fixLinkUrl}#media`,
        icon: `icon-chevron-right`,
        external: false,
        class: `fill-right`,
      });
    }

    if (this.tickets) {
      if (
        this.isFixtureInFuture(this.fixture) &&
        this.getSideFromFixture(this.fixture, this.selectedTeamFromCms?.id)
          ?.value === `Home`
      ) {
        const ticketsLink = this.getTicketsLink(this.fixture);

        if (ticketsLink) {
          this.buttons.push({
            label: `Tickets`,
            icon: `icon-ticket`,
            url: ticketsLink,
            external: true,
            class: `fill-right button--secondary`,
          });
        }
      }
    }

    if (this.redTV) {
      // RedTV logic here e.g. there is a video tagged with the fixture
      if (
        (this.isFixtureLive(this.fixture) ||
          this.isFixtureInPast(this.fixture)) &&
        this.doesFixtureHaveABroadcaster(this.fixture)
      ) {
        this.buttons.push({
          label: `RedTV`,
          icon: `icon-tv`,
          url: `${fixLinkUrl}#media`,
          external: true,
          class: `fill-right button--secondary`,
        });
      }
    }

    if (this.stats) {
      const firstTeam = this.getDefault(`team`);
      const isFirstTeamFixture = this.getTeam(this.fixture)?.team?.id === firstTeam.id ||
        this.getTeam(this.fixture, `Away`)?.team?.id === firstTeam.id;
      if (
        isFirstTeamFixture &&
        (
          this.isFixtureLive(this.fixture) ||
          this.isFixtureInPast(this.fixture)
        )
      ) {
        this.buttons.push({
          label: `Stats`,
          icon: `icon-stats-dots`,
          url: `${fixLinkUrl}#stats`,
          external: false,
          class: `fill-right button--secondary`,
        });
      }
    }

    if (this.addToCalendar) {
      if (this.isFixtureInFuture(this.fixture)) {
        const fixtureCalendarUrl = this.getCalendarLink({
          entity: `fixture`,
          id: this.fixture.id,
        });

        this.buttons.push({
          label: `Add to calendar`,
          icon: `icon-calendar`,
          url: fixtureCalendarUrl,
          external: true,
          class: `fill-right button--secondary`,
        });
      }
    }
  },
  methods: {
    async getFixtureLinks () {
      const request = await this.$getFixLink(this.fixture.id).catch(
        _ => null,
      );
      if (!request) return null;
      return request;
    },
  },
};
