
export default {
  props: {
    title: {
      type: String,
      default: ``,
    },
    teamId: {
      type: [String, Boolean],
      default: false,
    },
    numFixtures: {
      type: [Number, String],
      default: 3,
    },
    numResults: {
      type: [Number, String],
      default: 2,
    },
    refreshTime: {
      type: [Number, String],
      default: 20,
    },
  },
  data () {
    return {
      results: [],
      fixtures: [],
      selectedTeam: ``,
      interval: null,
    };
  },
  computed: {
    fixtureResults () {
      const fixtureResults = [];

      // Build fixtures & results objects with additional info

      // Results & Live Matches
      if (this.results.length) {
        for (const result of this.results) {
          fixtureResults.push({
            ...result.fixture,
            side: result.side,
            displayType: result.fixture.state.fixtureGrouping,
            competitionColours: this.getCompetitionColours(result),
            score: this.getScore(result),
            result: this.getRelativeResult(result.fixture, this.selectedTeam),
          });
        }
      }

      // Upcoming Matches
      if (this.fixtures.length) {
        for (const fixture of this.fixtures) {
          if (fixture.fixture.state.statusName === `Postponed`) {
            fixtureResults.push({
              ...fixture.fixture,
              side: fixture.side,
              displayType: fixture.fixture.state.fixtureGrouping,
              competitionColours: this.getCompetitionColours(fixture),
              score: this.getScore(fixture),
              result: this.getRelativeResult(fixture.fixture, this.selectedTeam),
            });
          } else {
            fixtureResults.push({
              ...fixture.fixture,
              side: fixture.side,
              displayType: fixture.fixture.state.fixtureGrouping,
              competitionColours: this.getCompetitionColours(fixture),
              score: null,
              result: null,

            });
          }
        }
      }
      return fixtureResults;
    },
    team () {
      return this.teamId || this.getDefault(`team`).id; // Return prop or get default team
    },
    baseParams () {
      return {
        include: `fixture.stage.season.competition,fixture.venue.venueNames,fixture.state,side`,
        filter: {
          'teamId': this.selectedTeam,
          // temp fix because otherwise it doesn't consider fixtures that happen on the same day.
          'fixture.datetime': this.$dayjs().add(1, `day`)
            .format(`YYYY-MM-DD`),
        },
        filterOptions: {
          "fixture.datetime": this.$dayjs(),
        },
      };
    },
  },
  mounted () {
    if (this.team) {
      this.selectedTeam = this.team;
      this.getFixturesResults();
    }
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  methods: {
    getFixturesResults (teamChanged) {
      this.getResults();
      this.getFixtures();

      if (teamChanged) {
        // Start a new refresh interval
        clearInterval(this.interval);
      }

      const refresh = this.refreshTime ? parseInt(this.refreshTime) * 1000 : 0;
      if (refresh && refresh > 0) {
        this.interval = setInterval(() => {
          this.getResults();
          this.getFixtures();
        }, refresh);
      } else {
        clearInterval(this.interval);
      }
    },
    async getResults () {
      const params = {
        ...this.baseParams,
        paginate: this.numResults,
        filterOptions: {
          'fixture.datetime': `lessThan`,
        },
        sort: {
          'fixture.datetime': `desc`,
        },
      };

      const response = await this.$getStats(`fixtureTeam`, params);
      if (response !== null) {
        this.results = response.data;
      }
    },
    async getFixtures () {
      const params = {
        ...this.baseParams,
        paginate: this.numFixtures,
        filterOptions: {
          'fixture.datetime': `greaterThan`,
        },
        sort: {
          'fixture.datetime': `asc`,
        },
      };
      const response = await this.$getStats(`fixtureTeam`, params);
      if (response !== null) {
        this.fixtures = response.data;
      }
    },
    getScore (fixture) {
      if (fixture.fixture.state.statusName === `Postponed`) {
        return `P-P`;
      }

      const teams = fixture.fixture.fixtureTeams;
      if (teams) {
        const homeTeam = teams.find(team => team.side.name === `Home`);
        const awayTeam = teams.find(team => team.side.name === `Away`);
        return `${homeTeam.score}-${awayTeam.score}`;
      }
      return ``;
    },
  },
};
